<template>
  <v-row class="match-height">
    <!-- Multiple Column -->
    <v-col
      v-if="!isLoading"
      cols="12"
    >
      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                block
                dark
                large
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-card>
                <v-card-title>{{ $t('lbl.settingGeneral') }}</v-card-title>
                <v-card-text>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="model.botTitle"
                        class="pb-5"
                        :label="$t('lbl.titleChat')"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="model.inputPlaceholder"
                        class="pb-5"
                        :label="$t('lbl.inputPlaceholder')"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.avatarSize"
                        class="pb-5"
                        :label="$t('lbl.avatarSize')"
                        outlined
                        dense
                        hide-details
                        type="number"
                        min="1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <h3 class="text-center ">
                        {{ $t('lbl.colorTheme') }}
                      </h3>
                      <v-color-picker
                        v-model="model.colorScheme"
                        :hide-canvas="true"
                        :hide-inputs="false"
                        :hide-mode-switch="true"
                        mode="hexa"
                        :show-swatches="false"
                        width="100%"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-card>
                <v-card-title class="text-center">
                  {{ $t('lbl.settingBot') }}
                </v-card-title>
                <v-card-text>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                      class="text-center pb-5"
                    >
                      <v-avatar :size="model.avatarSize">
                        <img
                          v-if="previewAvatarBot !== null"
                          :src="previewAvatarBot"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-file-input
                        v-model="avatarBot"
                        accept="image/*"
                        show-size
                        outlined
                        dense
                        label="Avatar"
                        hide-details
                        @change="setAvatarBot"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <h3 class="text-center ">
                        {{ $t('lbl.bgMessage') }}
                      </h3>
                      <v-color-picker
                        v-model="model.msgBgBot"
                        :hide-canvas="true"
                        :hide-inputs="false"
                        :hide-mode-switch="true"
                        mode="hexa"
                        :show-swatches="false"
                        width="100%"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <h3 class="text-center ">
                        {{ $t('lbl.colorMessage') }}
                      </h3>
                      <v-color-picker
                        v-model="model.msgColorBot"
                        :hide-canvas="true"
                        :hide-inputs="false"
                        :hide-mode-switch="true"
                        mode="hexa"
                        :show-swatches="false"
                        width="100%"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-card>
                <v-card-title class="text-center">
                  {{ $t('lbl.settingUser') }}
                </v-card-title>
                <v-card-text>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                      class="text-center pb-5"
                    >
                      <v-avatar :size="model.avatarSize">
                        <img
                          v-if="previewAvatarUser !== null"
                          :src="previewAvatarUser"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-file-input
                        v-model="avatarUser"
                        accept="image/*"
                        show-size
                        outlined
                        dense
                        label="Avatar"
                        hide-details
                        @change="setAvatarUser"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <h3 class="text-center ">
                        {{ $t('lbl.bgMessage') }}
                      </h3>
                      <v-color-picker
                        v-model="model.msgBgUser"
                        :hide-canvas="true"
                        :hide-inputs="false"
                        :hide-mode-switch="true"
                        mode="hexa"
                        :show-swatches="false"
                        width="100%"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="pt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <h3 class="text-center ">
                        {{ $t('lbl.colorMessage') }}
                      </h3>
                      <v-color-picker
                        v-model="model.msgColorUser"
                        :hide-canvas="true"
                        :hide-inputs="false"
                        :hide-mode-switch="true"
                        mode="hexa"
                        :show-swatches="false"
                        width="100%"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      avatarBot: null,
      avatarUser: null,
      previewAvatarBot: null,
      previewAvatarUser: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
      },
      model: {},
      id_model: -1,
      permisos: [],
      loading: false,
    }
  },
  created() {
    this.loadPermisos()
    this.getItem()
  },
  methods: {
    getItem() {
      this.axios
        .get('configuration/chatbot-setting', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.model = res.data.data.data.data
            this.id_model = res.data.data.data.id
            this.previewAvatarBot = process.env.VUE_APP_API_URL_PLUBLIC + this.model.botAvatarImg
            this.previewAvatarUser = process.env.VUE_APP_API_URL_PLUBLIC + this.model.userAvatarImg
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    loadPermisos() {
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.permisos = per.data.data
        })
    },
    setAvatarBot() {
      this.previewAvatarBot = URL.createObjectURL(this.avatarBot)
    },
    setAvatarUser() {
      this.previewAvatarUser = URL.createObjectURL(this.avatarUser)
    },
    save() {
      if (
        this.model.botTitle
        && this.model.colorScheme
        && this.model.avatarSize
        && this.model.msgBgBot
        && this.model.msgColorBot
        && this.model.msgBgUser
        && this.model.msgColorUser
        && this.model.inputPlaceholder
      ) {
        this.loading = true
        const formData = new FormData()
        if (this.avatarBot) {
          formData.append('botAvatarImg', this.avatarBot)
        }
        if (this.avatarUser) {
          formData.append('userAvatarImg', this.avatarUser)
        }

        const json = JSON.stringify({
          botTitle: this.model.botTitle,
          colorScheme: this.model.colorScheme,
          textColor: '#FFFFFF',
          avatarSize: this.model.avatarSize,
          msgBgBot: this.model.msgBgBot,
          msgColorBot: this.model.msgColorBot,
          msgBgUser: this.model.msgBgUser,
          msgColorUser: this.model.msgColorUser,
          inputPlaceholder: this.model.inputPlaceholder,
        })

        formData.append('data', json)

        this.axios
          .post('configuration/chatbot/chatbot-setting', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              window.location.reload()
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
